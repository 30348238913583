<template>
  <v-col cols="12" class="mx-0 mt-0 py-0 pr-0">
    <div class="mx-auto mk-page-title-search-create-section">
      <v-row>
        <v-col
          :cols="$vuetify.breakpoint.mdAndDown ? '4' : '3'"
          class="mk-batch-page-title pt-0 pb-0 ma-0"
          ><h1>{{ pageTitle }}</h1></v-col
        >
        <v-col cols="6" class="pa-0 ma-0" style="visibility:hidden;">
          <v-row class="mr-5">
            <v-col cols="10" class="ma-0 pa-0">
              <v-text-field
                flat
                solo
                :placeholder="placeholder"
                hide-details
              ></v-text-field>
            </v-col>
            <v-col cols="2" class="ma-0 pa-0 mk-page-search-btn-section"
              ><v-btn
                block
                depressed
                tile
                class="white--text q-search mk-page-search-btn"
                color="#0097D9"
              >
                <!-- <v-icon>fas fa-search</v-icon> -->
                <!-- <img src="../assets/img/icons/Search_1280.svg" alt="" /> -->
                <span class="material-icons"> search </span>
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
        <v-col
          :cols="$vuetify.breakpoint.mdAndDown ? '2' : '3'"
          class="pa-0 mk-page-add-btn-section"
        >
          <div class="mk-page-add-btn">
            <v-btn
              depressed
              block
              color="#0099DC"
              class="white--text rounded-lg custom-font-family"
              x-large
              :disabled="!shouldActiveCreateBtn"
              @click="$emit('createEvent')"
              ><v-icon>add</v-icon
              >{{ $vuetify.breakpoint.mdAndDown ? "" : createBtn }}</v-btn
            >
          </div>
        </v-col>
      </v-row>
    </div>
  </v-col>
</template>

<script>
export default {
  name: "PageTitleSearchCreate",
  props: {
    pageTitle: String,
    placeholder: String,
    createBtn: String,
    shouldActiveCreateBtn: {
      type: Boolean,
      default: true
    }
  },
  methods: {}
};
</script>

<style lang="scss">

.custom-font-family,
.custom-font-family.v-card__title,
button.custom-font-family {
  font-family: "Roboto", "Helvetica", "sans-serif" !important;
}
.mk-page-title-search-create-section .v-text-field,
.mk-page-title-search-create-section .v-input__control,
.mk-page-title-search-create-section .v-input__slot,
.mk-page-title-search-create-section .v-text-field__slot {
  height: 100%;
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
}

.mk-page-title-search-create-section {
  // For larger screen
  @media screen and (min-width: 1904px) {
    margin-bottom: 37px;
    height: 55px;
  }

  /* For normal Screen */
  @media screen and (min-width: 1264px) and (max-width: 1903px) {
    margin-bottom: 23px;
    height: 50px;
  }

  // For tab
  @media screen and (max-width: 1263px) {
    height: 40px;
    max-width: 550px;
    margin-bottom: 20px;
  }
}

.mk-page-search-btn {
  // For larger screen
  @media screen and (min-width: 1904px) {
    height: 53px !important;
  }

  /* For normal Screen */
  @media screen and (min-width: 1264px) and (max-width: 1903px) {
    height: 50px !important;
  }

  // For tab
  @media screen and (max-width: 1263px) {
    height: 40px !important;
  }
}

.mk-page-search-icon {
  // For larger screen
  @media screen and (min-width: 1904px) {
    height: 55px;
    width: 100%;
  }

  /* For normal Screen */
  @media screen and (min-width: 1264px) and (max-width: 1903px) {
    height: 50px;
    width: 100%;
  }

  // For tab
  @media screen and (max-width: 1263px) {
    height: 40px;
    width: 100%;
  }
}

.mk-batch-page-title {
  display: flex;
  align-items: center;
  justify-content: flex-start;

  // For larger screen
  @media screen and (min-width: 1904px) {
    padding-right: 0px !important;
  }

  /* For normal Screen */
  @media screen and (min-width: 1264px) and (max-width: 1903px) {
    padding-right: 0px !important;
  }
}

.mk-batch-page-title > h1 {
  // For larger screen
  @media screen and (min-width: 1904px) {
    // width: 39px;
    font-size: 30px;
    letter-spacing: 0.9px;
  }

  /* For normal Screen */
  @media screen and (min-width: 1264px) and (max-width: 1903px) {
    // width: 33px;
    font-size: 25px;
    letter-spacing: 0.75px;
  }

  // For tab
  @media screen and (max-width: 1263px) {
    // width: 26px;
    font-size: 20px;
    letter-spacing: 0.6px;
  }
}

.mk-page-add-btn {
  // For larger screen
  @media screen and (min-width: 1904px) {
    width: 95%;
    height: 55px;
  }

  /* For normal Screen */
  @media screen and (min-width: 1264px) and (max-width: 1903px) {
    width: 95%;
    height: 50px;
    font-size: 18px;
  }

  // For tab
  @media screen and (max-width: 1263px) {
    width: 82%;
    height: 40px;
  }
}

.mk-page-add-btn button {
  // For tab
  @media screen and (max-width: 1263px) {
    height: 40px !important;
  }
}

.v-text-field.v-text-field--solo .v-input__control {
  // For tab
  @media screen and (max-width: 1263px) {
    min-height: 40px;
  }
}
</style>
